define('janes-forest/models/marker', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    item: attr('string'),
    lat: attr('number'),
    lng: attr('number'),
    location: attr('string'),
    people: attr('string'),
    planted: attr('string'),
    uid: attr('string'),
    photos: hasMany('photos')
  });
});