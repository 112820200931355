define('janes-forest/app', ['exports', 'ember', 'janes-forest/resolver', 'ember-load-initializers', 'janes-forest/config/environment'], function (exports, _ember, _janesForestResolver, _emberLoadInitializers, _janesForestConfigEnvironment) {

  var App = undefined;

  var EmberApplication = _ember['default'].Application;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = EmberApplication.extend({
    modulePrefix: _janesForestConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _janesForestConfigEnvironment['default'].podModulePrefix,
    Resolver: _janesForestResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _janesForestConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});