define('janes-forest/router', ['exports', 'ember', 'janes-forest/config/environment'], function (exports, _ember, _janesForestConfigEnvironment) {
  var EmberRouter = _ember['default'].Router;

  var Router = EmberRouter.extend({
    location: _janesForestConfigEnvironment['default'].locationType,
    rootURL: _janesForestConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('map', function () {
      this.route('plant');
    });
    this.route('about');
  });

  exports['default'] = Router;
});