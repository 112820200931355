define('janes-forest/routes/application', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Route.extend({
    firebaseApp: inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      return get(this, 'session').fetch().then(function () {
        if (!get(_this, 'firebaseApp').auth().currentUser.emailVerified) {
          get(_this, 'session').close();
          _this.transitionTo('/');
        }
      })['catch'](function () {});
    },

    actions: {
      verifyEmail: function verifyEmail() {
        var _this2 = this;

        var user = get(this, 'firebaseApp').auth().currentUser;
        user.sendEmailVerification().then(function () {
          get(_this2, 'notifications').success('Email sent');
        }, function (error) {
          get(_this2, 'notifications').error(error.message || 'Something went wrong');
        });
      },

      signIn: function signIn(email, password) {
        var _this3 = this;

        get(this, 'session').open('firebase', {
          provider: 'password',
          email: email,
          password: password
        }).then(function (data) {
          set(_this3, 'email', null);
          set(_this3, 'password', null);
          if (!data.currentUser.emailVerified) {
            _this3.send('verifyEmail');
            get(_this3, 'notifications').warning('Please check your email to confirm your email address. Then sign in again.');
            get(_this3, 'session').close();
          } else {
            set(_this3, 'session.uid', data.uid);
            _this3.transitionTo('map');
          }
        }, function (error) {
          get(_this3, 'notifications').error(error.message || 'Something went wrong');
        });
      },

      signOut: function signOut() {
        get(this, 'session').close();
      },

      resetPassword: function resetPassword(email) {
        var _this4 = this;

        var auth = get(this, 'firebaseApp').auth();
        auth.sendPasswordResetEmail(email).then(function () {
          get(_this4, 'notifications').success('Email sent');
        }, function (error) {
          get(_this4, 'notifications').error(error.message || 'Something went wrong');
        });
      },

      signUp: function signUp(email, password) {
        var _this5 = this;

        var auth = get(this, 'firebaseApp').auth();
        return auth.createUserWithEmailAndPassword(email, password).then(function () {
          get(_this5, 'session').open('firebase', {
            provider: 'password',
            email: email,
            password: password
          }).then(function (data) {
            set(_this5, 'email', null);
            set(_this5, 'password', null);
            _this5.send('verifyEmail');
            set(_this5, 'session.uid', data.uid);
            get(_this5, 'notifications').warning('Please check your email to confirm your email address. Then sign in again.');
            get(_this5, 'session').close();
          }, function (error) {
            get(_this5, 'notifications').error(error.message || 'Something went wrong');
          });
        }, function (error) {
          get(_this5, 'notifications').error(error.message || 'Something went wrong');
        });
      }
    }
  });
});