define('janes-forest/routes/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  exports['default'] = Route.extend({
    beforeModel: function beforeModel() {
      if (get(this, 'session.isAuthenticated')) {
        this.transitionTo('map');
      }
    }
  });
});