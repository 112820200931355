define('janes-forest/controllers/map', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = Controller.extend({
    firebaseApp: inject.service(),
    lat: 42.356292325532905,
    lng: -71.06429100036623,
    zoom: 8,
    showAll: false,

    actions: {
      updateCenter: function updateCenter(e) {
        var center = e.target.getCenter();
        set(this, 'lat', center.lat);
        set(this, 'lng', center.lng);
      },
      upload: function upload(marker, event) {
        var _this = this;

        var storage = get(this, 'firebaseApp').storage().ref();
        var file = event.target.files[0];
        storage.child(file.name).put(file).then(function (snapshot) {
          event.target.value = null;
          var photo = get(_this, 'store').createRecord('photo', {
            url: snapshot.downloadURL,
            marker: marker
          });
          marker.get('photos').addObject(photo);
          marker.save().then(undefined, function (error) {
            get(_this, 'notifications').error(error.message || 'Something went wrong');
            marker.rollbackAttributes();
          });
          photo.save().then(undefined, function (error) {
            get(_this, 'notifications').error(error.message || 'Something went wrong');
            photo.rollbackAttributes();
          });
        }, function (error) {
          event.target.value = null;
          get(_this, 'notifications').error(error.message || 'Something went wrong');
        });
      },
      removePhoto: function removePhoto(photo) {
        photo.destroyRecord();
      },
      locate: function locate(marker) {
        var lat = get(marker, 'lat');
        var lng = get(marker, 'lng');
        if (get(this, 'lat') != lat) {
          set(this, 'lat', lat);
        }
        if (get(this, 'lng') != lng) {
          set(this, 'lng', lng);
        }
        set(this, 'zoom', 14);
      },
      plant: function plant() {
        get(this, 'store').createRecord('marker', {
          lat: get(this, 'lat'),
          lng: get(this, 'lng'),
          isOpen: true,
          uid: get(this, 'session.uid')
        });
      },
      save: function save(marker) {
        var _this2 = this;

        set(marker, 'isOpen', true);
        marker.save().then(function () {
          set(marker, 'isOpen', false);
        }, function (error) {
          get(_this2, 'notifications').error(error.message || 'Something went wrong');
          marker.rollbackAttributes();
        });
      },
      rollback: function rollback(marker) {
        if (!get(marker, 'isDeleted')) {
          if (get(marker, 'isNew')) {
            marker.destroyRecord();
          } else {
            marker.rollbackAttributes();
          }
        }
      },
      'delete': function _delete(marker) {
        set(marker, 'isOpen', true);
        set(marker, 'isOpen', false);
        marker.destroyRecord();
      },
      locateMe: function locateMe() {
        var _this3 = this;

        window.navigator.geolocation.getCurrentPosition(function (location) {
          set(_this3, 'lat', location.coords.latitude);
          set(_this3, 'lng', location.coords.longitude);
        });
      },
      updateLocation: function updateLocation(marker, e) {
        var _this4 = this;

        var location = e.target.getLatLng();
        setProperties(marker, {
          lat: location.lat,
          lng: location.lng
        });
        marker.save().then(undefined, function (error) {
          get(_this4, 'notifications').error(error.message || 'Something went wrong');
          marker.rollbackAttributes();
        });
      },
      showAll: function showAll() {
        this.toggleProperty('showAll');
      }
    }
  });
});