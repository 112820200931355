define('janes-forest/initializers/inject-notifications', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'notifications', 'service:notification-messages');
    application.inject('route', 'notifications', 'service:notification-messages');
  }

  exports['default'] = {
    name: 'inject-notifications',
    initialize: initialize
  };
});